.bi-card {
	min-width: 180px !important;
	width: 20vw;
	max-width: 300px;
	margin-bottom: 0 !important;
}

.bi-magnified:hover {
	cursor: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7), auto;
	z-index: 10000;
}
