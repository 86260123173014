.scrollable {
  height: calc(100vh - 150px); 
  overflow-y: scroll;
}

.focus-select {
	flex: 1
}
.focus-option {
	cursor: pointer;
	padding: 4px 8px;
	border-radius: 4px;
}
.focus-option:hover {
	background-color: rgb(33, 28, 162);
	color: white;
}

.bi-focus-capture {
	outline: 10px solid #ff000066;
	cursor: crosshair;
}