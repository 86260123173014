.image_subject_label, .image_action_label, .image_emotion_label, .image_object_label, .image_face_label {
  color: #eee;
  font-size: 90%;
  padding: 2px 4px;
  white-space: nowrap;
  line-height: 24px;
}
.image_subject_label {
  background-color: #FF4F1F;
}
.image_action_label {
  background-color: #007A6A;
}
.image_emotion_label {
  background-color: #5F523F;
}
.image_object_category, .image_face_demographic, .image_palette {
  font-size: 90%;
}
.image_object_label {
  background-color: #5F523F;
}
.image_face .image_face_label {
  background-color: rgb(70, 130, 180);
}
.image_face canvas {
  width: 48px;
  float: left;
  margin-right: 8px;
  border-radius: 8px;
}
.image_face, .image_palette {
  clear: both;
}
.image_palette {
  text-align: center;
}
.image_palette .image_palette_swatch {
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: top;
}
.image_reload, .image_edit {
  cursor: pointer;
  font-size: 80%;
}
ol.explainer li {
  margin-bottom: 24px;
}
ul.tick_list {
  list-style-type: none;
  padding: 12px 0 0 75px;
}
ul.tick_list li::before {
  content: "✓";
  padding: 1px 4px;
  border-radius: 10px;
  margin-right: 8px;
  background-color: #30B32D;
  color: white;  
}
ul.tick_list li {
  text-indent: -27px;
  margin-bottom: 12px;
}

.star-rating .mdi {
	font-size: 24px;
  color: #ffc107; /* Color for the stars */
  margin-right: 5px;
}