.bi-apps .nav-link, .bi-more-apps button {
	text-transform: capitalize;
}

#font-picker {
	width: 100%;
}

.palette-color {
	border: 1px solid var(--bs-border-color-translucent);
	border-radius: 4px;
	width: 38px;
	padding: 8px;
	cursor: pointer;
}

.palette-color-selected {
	border-color: #556EE6;
}

.palette-color div:not(.color-picker-hidden) {
	width: 100%;
	height: 20px;
	border-radius: 4px;
}

.color-picker-hidden {
	width: 0px;
	height: 0px;
	overflow: hidden;
}

#font-button-material-icons, #font-button-material-icons-outlined, #font-button-material-icons-round, #font-button-material-icons-sharp, #font-button-material-icons-two-tone {
	font-family: Open Sans !important;
}

.bi-magnified:hover {
	cursor: none;
	z-index: 10000;
}

.bbtn rect {
	fill: transparent;
	stroke: transparent;
    transition: fill .15s ease-in-out,stroke .15s ease-in-out
}

.bbtn text {
	cursor: pointer;
	text-decoration: none;
	-webkit-user-select: none;
	user-select: none;
	transition: fill .15s ease-in-out
}

.card .nav-link.active {
	background: linear-gradient(to bottom, #eeeeee 0%, #ffffff 100%);
}
.card .stickers-nav-link.active {
	background: repeating-conic-gradient(#eeeeee 0% 25%, transparent 0% 50%) 50% / 20px 20px
}

svg + .bi-kebab {
	opacity: 0;
	cursor: pointer;
}
svg:hover + .bi-kebab, .bi-kebab:hover { 
	opacity: 1; 
}

.bi-menu {
  min-width: 150px;
  position: fixed;
  background-color: rgb(225, 224, 223);
  border: 1px solid #cccccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  padding: 5px;
  display: none;
  z-index: 999999999;
}

.bi-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.bi-menu li {
  padding: 2px 7px;
  cursor: pointer;
  font-size: 13px;
  color: #333333;
}

.bi-menu li:hover {
  background-color: rgb(106, 160, 248);
  color: #ffffff; 
  border-radius: 5px;
}

.bi-menu .separator {
  height: 1px;
  background-color: #cccccc;
  margin: 5px 7px;
  padding: 0;
  pointer-events: none;
}

.bi-menu a {
  color: inherit; 
  text-decoration: none; 
}
